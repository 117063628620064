.App {
  text-align: center;
}

a[aria-current="page"] {
  background-color: #3e4049 !important;
  color: white;
  border-left: 4px solid white;
}

.no-underline {
  text-decoration: none !important;
}

.table-button {
  display: flex;
  align-items: center;
  background-color: #3f51b5;
  padding: 10px;
  color: white;
  font-size: 20px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-size: 14px;
  text-transform: uppercase;
}

.table-button:hover {
  background-color: #30409f;
}

.icon-left {
  margin-right: 5px;
}

.menu-icon {
  color: #9fa2b4;
  padding: 0;
}

header {
  min-height: 64px;
}

.container {
  padding-top: 3em;
  padding-left: 5vw;
  padding-right: 5vw;
}

.responsive-container {
  padding-top: 3em;
  padding-left: 5vw;
  padding-right: 5vw;
}

.no-cover {
  margin-top: 64px;
}
